import React, { useState, useMemo } from 'react';
import moment from 'moment';
import Calendar from './Calendar';
import classNames from 'classnames';
import 'react-day-picker/style.css';

const momentDate = (date) => moment(date).format('ddd, D MMM YYYY');

export default ({ rangeLimit, timePeriod, onDateChange }) => {
  const [selectedRange, setSelectedRange] = useState({
    from: timePeriod.startDate,
    to: timePeriod.endDate
  });

  const setStartAndEndDate = (startDate) => {
    let momentStartDate = moment(startDate);
    let momentEndDate = moment(selectedRange.to);

    if (momentStartDate && momentEndDate) {
      if (momentStartDate > momentEndDate) momentEndDate = momentStartDate;

      if (momentEndDate.diff(momentStartDate, 'days') > rangeLimit) {
        momentEndDate = moment(momentStartDate.toDate()).add(rangeLimit, 'day');
      }
    }

    if (!momentEndDate.isValid()) { momentEndDate = momentStartDate.clone() }

    const range = { from: momentStartDate.toDate(), to: momentEndDate.toDate() };
    setSelectedRange(range);
  };

  const setEndAndStartDate = (endDate) => {
    let momentEndDate = moment(endDate);
    let momentStartDate = moment(selectedRange.from);

    if (momentStartDate && momentEndDate) {
      if (momentEndDate < momentStartDate) momentStartDate = momentEndDate;

      if (momentEndDate.diff(momentStartDate, 'days') > rangeLimit) {
        momentStartDate = moment(momentEndDate.toDate()).subtract(rangeLimit, 'day');
      }
    }

    if (!momentStartDate.isValid()) { momentStartDate = momentEndDate.clone() }

    const range = { from: momentStartDate.toDate(), to: momentEndDate.toDate() };
    setSelectedRange(range);
  };

  const enabledButton = useMemo(() => (selectedRange.from && selectedRange.to),
    [selectedRange.from, selectedRange.to]
  );

  const dateStr = useMemo(() => {
    const { from, to } = selectedRange;

    console.log(from, to)

    if (!from || !to) return '';

    let context = `${momentDate(from)} - ${momentDate(to)}`;
    if ((moment(from).isSame(to))) context = `${momentDate(from)}`;

    return context;
  }, [selectedRange.from, selectedRange.to]);


  return (
    <>
      <div className='calendar-wrapper'>
        <div className='calendar-left-part'>
          <div className='text-center'><h4>FROM</h4></div>
          <Calendar
            onDayClick={(d) => setStartAndEndDate(d)}
            selectedRange={selectedRange}
          />
        </div>

        <div className='calendar-right-part'>
          <div className='text-center'><h4>TO</h4></div>
          <Calendar
            onDayClick={(d) => setEndAndStartDate(d)}
            selectedRange={selectedRange}
          />
        </div>
      </div>

      <div className='text-center text-sm-center p-2'>
        {dateStr}
      </div>

      <div style={{margin: '0px 10px 10px 10px'}}>
        <button className={classNames(['btn btn-primary btn-block', {'btn-grey': !enabledButton}])}
                disabled={!enabledButton}
                onClick={() => onDateChange({ startDate: selectedRange.from, endDate: selectedRange.to})}>
        Apply
        </button>
      </div>
    </>
  )
};