import moment from "moment";

const options = {
  'academic': {
    months: ['Sep', 'Aug'],
    cutoffMonth: 8
  },
  'financial': {
    months: ['Apr', 'Mar'],
    cutoffMonth: 3
  }
};

// Constants for date formatting
const DATE_FORMAT = 'YYYY MMM D';

export function generateYearsList(listType) {
  // Validate the listType
  if (!options[listType]) {
    throw new Error(`Invalid listType: ${listType}`);
  }
  
  const currentDate = moment();
  const twoYearsAgo = currentDate.clone().subtract(1, 'years');
  const months = options[listType].months;
  const cutoffMonth = options[listType].cutoffMonth;

  const startYear = currentDate.month() > cutoffMonth ? currentDate.year() : currentDate.year() - 1;

  const yearsList = [];

  // Generate year ranges
  for (let year = startYear; year >= 2017; year--) {
    const previousYear = year - 1;
    const [startMonth, endMonth] = months;

    const startDate = moment(`${previousYear} ${startMonth} 1`, DATE_FORMAT);
    const endDate = moment(`${year} ${endMonth} 1`, DATE_FORMAT).endOf('month');

    if (endDate.isBefore(twoYearsAgo)) {
      yearsList.push({
        key: `listType${year}`,
        title: `${startMonth} ${previousYear} to ${endMonth} ${year}`,
        startDate: startDate,
        endDate: endDate
      });
    }
  }

  return yearsList;
}
