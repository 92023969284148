import React, { useState, useRef } from 'react'
import { Popover } from 'react-tiny-popover'
import DateRangeButton from './Button';
import DoubleCalendar from "./DoubleCalendar";
import Historical from "./Historical";
import classNames from 'classnames';

const PeriodButton = ({ children, onClick }) => (
  <button className='btn btn-link btn-block' onClick={onClick}>
    {children}
  </button>
);

export default ({ rangeLimit, timePeriods, timePeriod, setTimePeriod }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showHistorical, setShowHistorical] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [calendarTimePeriod, setCalendarTimePeriod] = useState({});
  const [historicalTimePeriod, setHistoricalTimePeriod] = useState({});

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);
  const ref = useRef();

  const onPeriodClick = (item) => {
    if (item.key === 'custom') {
      setShowCalendar(true);
      setShowHistorical(false);
      setCalendarTimePeriod({startDate: null, endDate: null});
      $(ref.current.popoverDiv).css({left: 0});
    } else if (item.key === 'historical') {
      setShowCalendar(false);
      setShowHistorical(true);
      setHistoricalTimePeriod({ startDate: null, endDate: null });
      $(ref.current.popoverDiv).css({ left: 0 });
    } else {
      setTimePeriod({...item, startDate: moment(item.startDate).format('YYYY-MM-DD'), endDate: moment(item.endDate).format('YYYY-MM-DD') });
      setIsPopoverOpen(false);
      setShowCalendar(false);
      setShowHistorical(false);
    }
  };

  const onDateChange = (selectedRange) => {
    setTimePeriod({
      ...selectedRange,
      startDate: moment(selectedRange.startDate),
      endDate: moment(selectedRange.endDate)
    });

    setIsPopoverOpen(false);
  };

  if (!timePeriods) return null;

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      align={'center'}
      disableReposition={true}
      onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
      ref={ref}
      padding={6}
      content={(
        <div className={classNames(['time-periods-box', { 'custom-time-periods-box': showCalendar}])}>
          <div className='list'>
            <ul className='ranges'>
              {timePeriods.map(item =>
                <li key={item.key}>
                  <PeriodButton onClick={() => onPeriodClick(item)}>{item.title}</PeriodButton>
                </li>
              )}
            </ul>
          </div>
          {showCalendar &&
          <div className='calendar'>
            <DoubleCalendar
              rangeLimit={rangeLimit}
              timePeriod={calendarTimePeriod}
              onDateChange={onDateChange}
            />
          </div>
          }
          {showHistorical &&
            <div className='historical'>
              <Historical
                rangeLimit={rangeLimit}
                timePeriod={historicalTimePeriod}
                onDateChange={onDateChange}
              />
            </div>
          }
          <div className='clearfix'/>
        </div>
      )}
    >
      <div style={{ position: 'relative' }}>
        <DateRangeButton
          timePeriods={timePeriods}
          timePeriod={timePeriod}
          togglePopover={togglePopover}
        />
      </div>
    </Popover>
  )
}