import React from 'react';
import { generateYearsList  } from './yearLists';

const List = ({ items, onClick }) => {
  const handleClick = (e, item) => {
    e.preventDefault();
    onClick(item);
  }

  return (
    <div className='items'>
      {items.map((item) => (
        <div key={item.key} className={'item text-center'}>
          <a href='#' onClick={(e) => handleClick(e, item)}>
            {item.title}
          </a>
        </div>
      ))}
    </div>
  )
}

export default ({ onDateChange }) => {
  const setStartAndEndDate = (item) => {
    onDateChange({ startDate: item.startDate, endDate: item.endDate });
  };

  return (
    <div className='historical-wrapper'>
      <div className='pull-left historical-left-part'>
        <div className='text-center'><h4>Academic year</h4></div>
        <List items={generateYearsList('academic') } onClick={setStartAndEndDate}/>
      </div>

      <div className='pull-left historical-right-part'>
        <div className='text-center'><h4>Financial Year</h4></div>
        <List items={generateYearsList('financial')} onClick={setStartAndEndDate}/>
      </div>

      <div className='clearfix'/>
    </div>
  )
};